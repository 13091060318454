<h3 class="ui-portal-title">
  Favorites
</h3>

<ui-grid
  [columns]="columns"
  [config]="config"
  [data]="model"
  (onAction)="onGridAction($event)"
>
  <!-- Template for Name column -->
  <ng-template #nameColumn let-row="row">
    <div class="app-name">
      <div class="app-icon">
        <img src="/assets/img/package.svg">
      </div>
      <a [routerLink]="'../../package/' + row.nws" class="ui-link text">{{row.name}}</a>
    </div>
  </ng-template>

</ui-grid>
