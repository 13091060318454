import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject} from '@angular/core';
import {TooltipDirective} from '../../../oex-ui-kit/components/tooltip/tooltip.directive';
import {
  GridComponent,
  IGridColumnDef,
  IGridConfig,
  IPagingData,
  IRowActionEvent
} from '../../../oex-ui-kit/components/grid/grid';
import {MatTooltip} from '@angular/material/tooltip';
import {APIService} from '../../services/api.service';
import {AuthService} from '../../services/authService';
import {IBookmark} from '../../services/api.types';
import {ActivatedRoute, ResolveFn, RouterLink} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ModalService} from '../../services/modal.service';

export const resolveSavedProducts: ResolveFn<IPagingData<IBookmark>> = () => {
  const api = inject(APIService);
  const auth = inject(AuthService);
  return api.getUserBookmarks(auth.getUserKey());
}

@Component({
  selector: 'oex-hih-saved-products',
  standalone: true,
  templateUrl: './saved-products.component.html',
  styleUrl: './saved-products.component.scss',
  imports: [
    TooltipDirective,
    GridComponent,
    MatTooltip,
    RouterLink
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedProductsComponent {
  columns: IGridColumnDef[] = [
    {text: 'App Name', field: 'name', template: 'nameColumn', width: '20%'},
    {text: 'Short Description', field: 'shortDescription', width: '50%'},
  ];
  config: IGridConfig = {
    rowActions: [
      {id: 'delete', icon: '/assets/img/delete.svg', tooltip: 'Delete'}
    ]
  };
  data$ = this.route.data.pipe(takeUntilDestroyed());
  model!: IPagingData<IBookmark>;

  constructor(private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private api: APIService,
              private modal: ModalService) {
    this.data$.subscribe(d => {
      this.model = d.data;
    });
  }

  private askForDeletion(data: IBookmark) {
    this.modal.show({
      title: 'Delete',
      message: `Do you really want to delete bookmark?`,
      cancel: true,
      buttons: [
        {text: 'No', cancel: true, close: true},
        {text: 'Yes', default: true, clickOnEnter: true, close: true, click: () => this.deleteRow(data)}
      ]
    });
  }

  private async deleteRow(b: IBookmark) {
    try {
      await this.api.deleteBookmark(b.id);
      const idx = this.model.items.findIndex(bookmark => bookmark.id === b.id);
      if (idx !== -1) {
        this.model.items.splice(idx, 1);
        this.model = structuredClone(this.model);
        this.cdr.detectChanges();
      }
    } catch (e) {
      this.modal.showError(e);
    }
  }

  onGridAction(event: IRowActionEvent<IBookmark>) {
    if (event.action.id === 'delete') {
      this.askForDeletion(event.data);
    }
  }
}
